import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import img11 from '../../assets/ilustracao_tela11.png'
import usePersistedState from '../../hooks/usePersistedState'

import Button from '../../components/Button'
import Header from '../../components/Header'

import { Container, Content, HandleNew, Line } from './styles'
import { UserData } from '../../utils/interfaces'

const End: React.FC = () => {
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)

  const history = useHistory()

  const handleNewParticipant = useCallback(() => {
    history.push('/')
    localStorage.removeItem('@BRFPrevFamilia:authorizeTitularPayment')
    localStorage.removeItem('@BRFPrevFamilia:erroProps')
    localStorage.removeItem('@BRFPrevFamilia:goalsSelected')
    localStorage.removeItem('@BRFPrevFamilia:PercentualValuePercent')
    localStorage.removeItem('@BRFPrevFamilia:receiveTypeSelected')
    localStorage.removeItem('@BRFPrevFamilia:TimeValueYears')
    localStorage.removeItem('@BRFPrevFamilia:totalRentability')
    localStorage.removeItem('@BRFPrevFamilia:userData')
    localStorage.removeItem('@BRFPrevFamilia:userDetails')
    localStorage.removeItem('@BRFPrevFamilia:tipoPessoa')
  }, [history])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <strong>
            Pronto! Seu pedido de adesão ao Plano Família BRF Previdência foi
            salvo com sucesso!
          </strong>
          <img src={img11} alt="Finalizar" />

          <p>
            Agora nossos especialistas irão conferir todos os detalhes para a
            conclusão da sua adesão.
          </p>

          <div>
            <strong>ACESSE O NOSSO SITE:</strong>
            <a
              href="https://www.brfprevidencia.com.br/plano-familia/"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.brfprevidencia.com.br
            </a>
          </div>

          <Line />

          <HandleNew>
            <h4>
              {userData.name.split(' ')[0]}, que tal realizar a adesão de mais
              algum familiar?
            </h4>
            <Button
              type="button"
              color="orange"
              width="large"
              onClick={handleNewParticipant}
            >
              Sim, eu quero!
            </Button>
          </HandleNew>
        </Content>
      </Container>
    </>
  )
}

export default End
