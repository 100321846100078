import React from 'react'

import { FiHelpCircle } from 'react-icons/fi'

import { Container } from './styles'

const Help: React.FC = () => {
  return (
    <>
      <a
        href="https://portalbrf.universalprev.com.br/form.jsp?sys=AUT&amp;formID=464569227&locale=pt_BR&cliente=BRF"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Container>
          <FiHelpCircle />
          <div className="hide">
            <p>Fale com um especialista</p>
          </div>
        </Container>
      </a>
    </>
  )
}

export default Help
