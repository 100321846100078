/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { FiCheck, FiCheckCircle } from 'react-icons/fi'
import simulationYearsInfo from '../../assets/simulation-years-info.svg'
import simulationPercentageInfo from '../../assets/simulation-percentage-info.svg'
import simulationSelectTimeIcon from '../../assets/simulation-select-time-icon.svg'
import simulationSelectPercentIcon from '../../assets/simulation-select-percent-icon.svg'

import { formatValueReduced, formatValue } from '../../utils/formatValues'
import usePersistedState from '../../hooks/usePersistedState'
import { Valor } from '../../utils/masks'

import Header from '../../components/Header'
import Button from '../../components/Button'

import {
  Container,
  Content,
  GraphWrapper,
  BoxButtons,
  ColoredBoxInfo,
  ReceiveBox,
  ButtonSelectBox,
  ButtonSimulationCalc,
  InfoValuesBox,
  ButtonSimulationValue,
} from './styles'
import { UserDetails } from '../../utils/interfaces'

interface CustomTooltipInterface {
  active: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any
  label: string
}

interface Values {
  year: number
  invested: number
  finalBalance: number
  rentability: number
}

const Simulation: React.FC = () => {
  const [userDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [selectedReceive, setSelectedReceive] = usePersistedState<
    'PD' | 'PS' | null
  >('receiveTypeSelected', null)

  const [totalRentability, setTotalRentability] = usePersistedState(
    'totalRentability',
    0,
  )
  const [timeValueYears, setTimeValueYears] = usePersistedState<number>(
    'TimeValueYears',
    5,
  )
  const [percentualValuePercent, setPercentualValuePercent] = usePersistedState<
    number
  >('PercentualValuePercent', 0.3)

  const [displayPercent, setDisplayPercent] = useState(false)
  const [displayTime, setDisplayTime] = useState(false)

  const [values, setValues] = useState<Values[]>([])
  const [totalInvested, setTotalInvested] = useState(0)
  const [totalBalance, setTotalBalance] = useState(0)
  const [launched, setLaunched] = useState(false)

  const history = useHistory()

  useEffect(() => setTotalRentability((totalBalance * 100) / totalInvested), [
    totalInvested,
    totalBalance,
    setTotalRentability,
  ])

  const percentualValue = useMemo(
    () => formatValue(totalBalance * (percentualValuePercent / 100)),
    [percentualValuePercent, totalBalance],
  )
  const timeValue = useMemo(
    () => formatValue(totalBalance / (timeValueYears * 12)),
    [timeValueYears, totalBalance],
  )

  const { contribuicaoBasica, years } = userDetails
  const actualYear = new Date().getFullYear()
  const lastYear = actualYear + parseInt(years.toString(), 10)

  const createValues = useCallback(() => {
    const valuesCalculated: Values[] = []
    // const investedByYear = 12 * contribuicaoBasica
    let totalInvestedTemp = totalInvested

    let temporaryFinalBalance = 0

    for (let i = actualYear; i <= lastYear; i += 1) {
      // const yearInvested = investedByYear
      // const rentability = 4
      const rentability = (1 + 4) ** (1 / 12 - 1)

      let finalBalance = 0

      for (let j = 1; j <= 12; j += 1) {
        const monthinvested = userDetails.contribuicaoBasica

        totalInvestedTemp += monthinvested

        const monthinvestedDesc = userDetails.contribuicaoBasica

        finalBalance =
          temporaryFinalBalance * (rentability / 100) +
          (monthinvestedDesc + temporaryFinalBalance)

        temporaryFinalBalance = finalBalance
      }
      // const finalBalance =
      //   temporaryFinalBalance +
      //   yearInvested +
      //   (temporaryFinalBalance + yearInvested) * (rentability / 100)

      // temporaryFinalBalance = finalBalance

      const val: Values = {
        year: i,
        invested: temporaryFinalBalance,
        rentability,
        finalBalance,
      }
      valuesCalculated.push(val)
    }

    setTotalInvested(contribuicaoBasica * parseInt(years.toString(), 10) * 12)
    setTotalBalance(temporaryFinalBalance)
    setValues(valuesCalculated)
    setLaunched(true)
  }, [
    actualYear,
    contribuicaoBasica,
    lastYear,
    totalInvested,
    userDetails,
    years,
  ])

  useEffect(() => {
    if (!launched) createValues()
  }, [createValues, launched])

  const toggleSelectedReceive = useCallback(
    (selectedReceiveNow: 'PD' | 'PS' | null) => {
      setSelectedReceive(selectedReceiveNow)
      if (selectedReceiveNow === 'PD') {
        setDisplayTime(true)
        setDisplayPercent(false)
      } else if (selectedReceiveNow === 'PS') {
        setDisplayTime(false)
        setDisplayPercent(true)
      } else {
        setDisplayPercent(false)
        setDisplayTime(false)
      }
    },
    [setSelectedReceive],
  )

  function adicPerct() {
    if (percentualValuePercent >= 1.2) {
      setPercentualValuePercent(1.2)
    } else {
      setPercentualValuePercent(percentualValuePercent + 0.05)
    }
  }

  function tiraPerct() {
    if (percentualValuePercent <= 0.3) {
      setPercentualValuePercent(0.3)
    } else {
      setPercentualValuePercent(percentualValuePercent - 0.05)
    }
  }

  function adicAno() {
    if (timeValueYears >= 25) {
      setTimeValueYears(25)
    } else {
      setTimeValueYears(timeValueYears + 1)
    }
  }

  function tiraAno() {
    if (timeValueYears <= 5) {
      setTimeValueYears(5)
    } else {
      setTimeValueYears(timeValueYears - 1)
    }
  }

  useEffect(() => {
    toggleSelectedReceive(selectedReceive)
  }, [selectedReceive, toggleSelectedReceive])

  const graphValues = values.map(value => ({
    year: value.year,
    value: value.finalBalance,
    formattedValue: Valor(value.finalBalance.toFixed(2)),
  }))

  function CustomTooltip({ active, payload, label }: CustomTooltipInterface) {
    if (active) {
      return (
        <div className="tooltip">
          <h4>Ano: {label}</h4>
          <p>
            Saldo: <span>{Valor(payload[0].value.toFixed(2))}</span>
          </p>
        </div>
      )
    }
    return null
  }

  const handleChangeValues = useCallback(() => {
    history.push('/contribution')
  }, [history])

  const handleConfirmValues = useCallback(() => {
    // history.push('/address-infos')
    history.push('/type-person')
  }, [history])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <div className="values-box">
            <InfoValuesBox color="green" gradientDirection="right">
              <span>Valor Investido</span>
              <h3>{formatValue(totalInvested)}</h3>
              <small>Seu investimento no período</small>
            </InfoValuesBox>

            <InfoValuesBox color="blue" gradientDirection="left">
              <span>Seu saldo projetado</span>
              <h3>{formatValue(totalBalance)}</h3>
              <small>Seu investimento + rentabilidade</small>
            </InfoValuesBox>
          </div>
          <article>
            <p>
              Os dados não mentem! <br />
              Quer ver? Arraste seu mouse no gráfico e descubra a evolução do
              seu patrimônio no decorrer dos próximos anos.
            </p>
          </article>

          <GraphWrapper>
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart data={graphValues}>
                <defs>
                  <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#FF863633" stopOpacity={0.8} />
                    <stop
                      offset="95%"
                      stopColor="#FF863644"
                      stopOpacity={0.05}
                    />
                  </linearGradient>
                </defs>

                <Area
                  type="monotone"
                  dataKey="value"
                  stroke="#FF8636"
                  fillOpacity={1}
                  fill="url(#color)"
                />

                <Tooltip
                  content={
                    <CustomTooltip active={false} payload={2} label="" />
                  }
                />
                <XAxis name="Ano" dataKey="year" />
                <YAxis
                  name="Saldo"
                  dataKey="value"
                  width={75}
                  axisLine={false}
                  tickLine={false}
                  tickCount={5}
                  tickFormatter={number =>
                    `${formatValueReduced(
                      parseInt(number.toString(), 10),
                      3,
                      'R$',
                    )}`
                  }
                />
                <CartesianGrid
                  strokeDasharray="3 3"
                  vertical={false}
                  opacity={0.5}
                />
              </AreaChart>
            </ResponsiveContainer>
          </GraphWrapper>
          <small>
            Os valores são simulações e não há garantia de rentabilidade futura.
            Para a projeção acima foi utilizado o percentual de 4% a.a.
          </small>
        </Content>

        <Content>
          <strong>Desejo receber o valor mensalmente via:</strong>
          <article>
            <p>
              <i>
                Esta é uma simulação. No momento da requisição do benefício você
                poderá escolher a forma de recebimento definitiva.
              </i>
            </p>
          </article>

          <ReceiveBox>
            <ButtonSelectBox
              type="button"
              onClick={() => toggleSelectedReceive('PS')}
              selected={selectedReceive === 'PS'}
            >
              <img src={simulationSelectPercentIcon} alt="Percentual" />
              <span>Percentual sobre o saldo</span>
              <FiCheckCircle />
            </ButtonSelectBox>
            <BoxButtons displayed={displayPercent}>
              <ButtonSimulationCalc type="button" onClick={() => tiraPerct()}>
                -
              </ButtonSimulationCalc>
              <ButtonSimulationValue type="button">
                <span>{parseFloat(percentualValuePercent.toFixed(2))}%</span>
              </ButtonSimulationValue>
              <ButtonSimulationCalc type="button" onClick={() => adicPerct()}>
                +
              </ButtonSimulationCalc>
            </BoxButtons>
            <ColoredBoxInfo
              size="large"
              color="purple"
              gradientDirection="right"
              displayed={displayPercent}
            >
              <div>
                <h3>{percentualValue}*</h3>
                <small>Você receberá por mês</small>
                <p>
                  *Valor simulado referente a{' '}
                  {percentualValuePercent.toFixed(1)}% do seu saldo projetado,
                  com pagamentos mensais efetuados com base no percentual que
                  escolher no momento da aposentadoria. <br />
                  Esgotado o saldo da conta de benefício concedido, cessam os
                  pagamentos de benefícios pelo plano.
                </p>
              </div>
              <img src={simulationPercentageInfo} alt="Percentual" />
            </ColoredBoxInfo>
          </ReceiveBox>

          <ReceiveBox>
            <ButtonSelectBox
              type="button"
              onClick={() => toggleSelectedReceive('PD')}
              selected={selectedReceive === 'PD'}
            >
              <img src={simulationSelectTimeIcon} alt="Anos" />
              <span>Prazo Determinado</span>
              <FiCheckCircle />
            </ButtonSelectBox>
            <BoxButtons displayed={displayTime}>
              <ButtonSimulationCalc type="button" onClick={() => tiraAno()}>
                -
              </ButtonSimulationCalc>
              <ButtonSimulationValue type="button">
                <span>{timeValueYears}</span>
              </ButtonSimulationValue>
              <ButtonSimulationCalc type="button" onClick={() => adicAno()}>
                +
              </ButtonSimulationCalc>
            </BoxButtons>
            <ColoredBoxInfo
              size="large"
              color="darkGreen"
              gradientDirection="right"
              displayed={displayTime}
            >
              <div>
                <h3>{timeValue}*</h3>
                <small>Você receberá por mês</small>
                <p>
                  *Valor simulado referente a modalidade de prazo determinado
                  com duração de {timeValueYears} anos calculado com base no seu
                  saldo projetado. <br />
                  Esgotado o saldo da conta de benefício concedido, cessam os
                  pagamentos de benefícios pelo plano.
                </p>
              </div>
              <img src={simulationYearsInfo} alt="Anos" />
            </ColoredBoxInfo>
          </ReceiveBox>

          <small className="comment">
            Esta simulação não representa garantia de valor de benefício futuro.
            Informamos que o valor da aposentadoria será apurado no momento da
            concessão do benefício, o que pode resultar em um valor diferente
            daquele apresentado no simulador.
          </small>
        </Content>

        <Button
          type="button"
          fontSize="normal"
          color="orange"
          width="large"
          disabled={selectedReceive === null}
          onClick={handleConfirmValues}
        >
          <FiCheck size={40} />
          Pronto! Quero continuar com adesão ao Plano
        </Button>

        <Button type="button" fontSize="small" onClick={handleChangeValues}>
          Ops! Quero alterar os valores e simular novamente
        </Button>
      </Container>
    </>
  )
}

export default Simulation
