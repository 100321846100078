import styled from 'styled-components'

interface ContentProps {
  isActive: boolean;
}

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;
  margin-bottom: 15px;
  max-width: 600px;
  padding: 0 5px;

  @media screen and (max-width: 766px) {
    max-width: 550px;
  }
`

export const DivContent = styled.main`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: #FFF;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 6px 12px rgba(61, 69, 67, 0.05);

  width: 100%;
  padding: 30px 5px;
  margin-bottom: 10px;
  text-align: center;

  @media screen and (max-width: 766px) {
    max-width: 550px;
  }
`

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 410px;
  width: ${props => props.isActive ? '100%' : '0px'};
  opacity: ${props => props.isActive ? 1 : 0};
  pointer-events: ${props => props.isActive ? 'initial' : 'none'};
  transition: opacity 0.3s ease-in-out;

  img {
    margin-bottom: 38px;
    height: 200px;
  }

  strong {
    font-size: 16px;
    margin-bottom: 15px;
    text-align: center;
  }

  > span {
    font-size: 15px;
    text-align: center;
  }

  > div{
    height: 25px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > span{
      flex: 1;
      font-size: 14px;
      margin-right: 15px;
    }
  }

  @media screen and (min-width: 400px) {
    max-width:400px;
    height: 430px;
    padding-top:15px;
    align-items: center;
    img {
      margin-bottom: 38px;
      height: 200px;
    }
    strong {
      font-size: 18px;
    }
    > span {
      font-size: 17px;
    }
    > div {
      height: 30px;
      margin-top:22px;
      align-items: center;
      >span {
        font-size: 16px;
      }
    }
  }
`

export const BtnVoltar = styled.button`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  border: none;
  background-color: unset;
  border-bottom: 1px solid transparent;
  color: ${({ theme }) => theme.colors.text};

  margin-bottom: 15px;

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }

  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }
`
