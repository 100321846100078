/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Base64 } from 'js-base64'
import { MdSecurity } from 'react-icons/md'
import { BiIdCard } from 'react-icons/bi'
import * as Yup from 'yup'
import getValidationErrors from '../../utils/getValidationErrors'
import usePersistedState from '../../hooks/usePersistedState'
import validaCPF from '../../utils/validaCPF'
import api from '../../services/api'
import Button from '../../components/Button'
import Header from '../../components/Header'
import Input from '../../components/Input'
import {
  Container,
  Content,
  RadioButton,
  BtnContato,
  Line,
  BtnModal,
} from './styles'
import { ConfigData, IndicationData, UserData } from '../../utils/interfaces'
import ModalBox from '../../components/Modal'
import InputSelect from '../../components/InputSelect'
import InputHidden from '../../components/InputHidden'

interface CpfFuncionario {
  cpf: string
}

const TypePerson: React.FC = () => {
  const [userData, setUserData] = usePersistedState<UserData>(
    'userData',
    {} as UserData,
  )
  const [, setCpfFunc] = usePersistedState<CpfFuncionario>(
    'cpfFuncionario',
    {} as CpfFuncionario,
  )
  const [indicationData, setIndicationData] = usePersistedState<IndicationData>(
    'indicationData',
    {} as IndicationData,
  )
  const [configData] = usePersistedState<ConfigData>(
    'configData',
    {} as ConfigData,
  )
  const [tipoPessoa, setTipoPessoa] = usePersistedState('tipoPessoa', 'F')
  const [cpfFuncionario, setCpfFuncionario] = useState('')
  const [inscPlano, setInscPlano] = useState('')
  const [parentIndicacao, setParentIndicacao] = useState({
    label: indicationData.dcrGrauParentescoIndicacao
      ? indicationData.dcrGrauParentescoIndicacao
      : '',
    value: indicationData.grauParentescoIndicacao
      ? indicationData.grauParentescoIndicacao
      : '',
  })
  const history = useHistory()
  const formRef = useRef<FormHandles>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [txtModal, setTxtModal] = useState('')

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const handleChangeParentesco = useCallback(option => {
    setParentIndicacao(option)
  }, [])

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          cpfPessoa: Yup.string()
            .required('CPF é obrigatório.')
            .test(
              '',
              'CPF inválido.',
              () =>
                validaCPF(
                  data.cpfPessoa.replaceAll('.', '').replace('-', ''),
                ) || data.cpfPessoa === '',
            ),
          inscPlano: Yup.string().when('tipoPessoa', {
            is: 'P',
            then: Yup.string().required('Código do plano é obrigatório.'),
          }),
          grauParentesco: Yup.string().when('tipoPessoa', {
            is: 'F',
            then: Yup.string().required('Selecione um grau de parentesco.'),
          }),
        })

        await schema.validate(data, { abortEarly: false })

        const cpf = data.cpfPessoa.replaceAll('.', '').replace('-', '')

        const parametros = Base64.encode(
          `{"versao":"1",
          "cliente":"${configData.codCliente}",
          "cpf":"${cpf}"
        }`,
        )

        const parametrosFinal = Base64.encode(parametros)

        await api
          .get(`wsParticipante.rule?sys=ADZ&Entrada=${parametrosFinal}`)
          .then(() => {
            if (tipoPessoa === 'P') {
              setUserData({
                ...userData,
                cpf: data.cpfPessoa,
                inscPlano,
              })
            } else {
              setIndicationData({
                cpfIndicacao: data.cpfPessoa,
                grauParentescoIndicacao: parentIndicacao.value,
                dcrGrauParentescoIndicacao: parentIndicacao.label,
              })
            }
            history.push('/register')
          })
          .catch(res => {
            if (res.message === 'Request failed with status code 404') {
              setTxtModal('O CPF digitado não consta em nossa base de dados')
              setIsModalOpen(true)
            } else {
              setTxtModal(
                'Falha de conexão com o servidor. Tente novamente mais tarde.',
              )
              setIsModalOpen(true)
            }
          })
      } catch (err) {
        formRef.current?.setErrors(
          getValidationErrors(err as Yup.ValidationError),
        )
      }
    },
    [
      configData.codCliente,
      tipoPessoa,
      history,
      setUserData,
      userData,
      inscPlano,
      setIndicationData,
      parentIndicacao.value,
      parentIndicacao.label,
    ],
  )

  const handleConfirmar = useCallback(event => {
    event.preventDefault()
    formRef.current?.submitForm()
  }, [])

  return (
    <>
      <Header />

      <Container>
        <Content>
          <strong>
            Você é participante de plano de benefícios ou familiar até 2° grau
            de participante?
          </strong>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <InputHidden
              type="hidden"
              name="tipoPessoa"
              value={tipoPessoa}
              placeholder=""
              readOnly
            />

            <RadioButton>
              <label>Escolha uma opção:</label>
              <div>
                <BtnContato
                  type="button"
                  isActive={tipoPessoa === 'F'}
                  onClick={() => setTipoPessoa('F')}
                >
                  Familiar
                </BtnContato>
                <BtnContato
                  type="button"
                  isActive={tipoPessoa === 'P'}
                  onClick={() => setTipoPessoa('P')}
                >
                  Participante
                </BtnContato>
              </div>
            </RadioButton>

            {tipoPessoa === 'P' ? (
              <p>
                Digite aqui o CPF para a validação do seu vínculo com a BRF
                Previdência:
              </p>
            ) : (
              <p>
                Digite aqui o CPF de seu parente que é participante dos planos
                da BRF Previdência:
              </p>
            )}

            <Input
              placeholder="CPF do participante"
              name="cpfPessoa"
              icon={MdSecurity}
              type="tel"
              mask="cpf"
              maxLength={11}
              onBlur={e => setCpfFuncionario(e.target.value)}
            />

            {tipoPessoa === 'P' ? (
              <Input
                name="inscPlano"
                placeholder="ID Patrocinadora"
                icon={BiIdCard}
                type="text"
                maxLength={10}
                value={inscPlano}
                onChange={e => setInscPlano(e.target.value)}
              />
            ) : (
              <InputSelect
                name="grauParentesco"
                options={[
                  { label: 'Conjuge', value: '1' },
                  { label: 'Companheiro(a)', value: '2' },
                  {
                    label: 'Filho(a) não emancipado menor de 21 anos',
                    value: '3',
                  },
                  { label: 'Filho(a) inválido(a)', value: '4' },
                  { label: 'Pai/mãe', value: '5' },
                  {
                    label:
                      'Irmão não emancipado menor de 21 anos com dependência econômica',
                    value: '6',
                  },
                  {
                    label: 'Irmão inválido com dependência econômica',
                    value: '7',
                  },
                  {
                    label:
                      'Enteado não emancipado menor de 21 anos com dependência econômica',
                    value: '8',
                  },
                  {
                    label: 'Enteado inválido com dependência econômica',
                    value: '9',
                  },
                  {
                    label:
                      'Menor tutelado não emancipado menor de 21 anos com dependência econômica',
                    value: '10',
                  },
                  {
                    label: 'Menor tutelado inválido com dependência econômica',
                    value: '11',
                  },
                  { label: 'Filho(a) maior de 21 anos', value: '12' },
                  { label: 'Outros', value: '0' },
                ]}
                placeholder="Grau de parentesco"
                onChange={e => handleChangeParentesco(e)}
                value={parentIndicacao}
              />
            )}

            <Line />
            <strong>Flexibilidade para o seu presente e futuro</strong>
            <span className="legenda">
              O Plano Família oferece a flexibilidade do resgate parcial, o
              recebimento dos benefícios temporários e resgate de valores
              acumulados para investimentos em viagens internacionais e
              intercâmbio, recursos para estudos nacionais e internacionais,
              casa própria e outros.
            </span>
          </Form>
        </Content>
        <Button type="submit" color="orange" onClick={handleConfirmar}>
          Avançar
        </Button>
        <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          {txtModal}
          <BtnModal isActive onClick={() => handleCloseModal()}>
            Ok
          </BtnModal>
        </ModalBox>
      </Container>
    </>
  )
}

export default TypePerson
