import React, { useEffect } from 'react'
import { Switch } from 'react-router-dom'

import Route from './Route'

import BemVindo from '../pages/BemVindo'
import Contribution from '../pages/Contribution'
import Simulation from '../pages/Simulation'
import TypePerson from '../pages/TypePerson'
import Register from '../pages/Register'
import ConfirmSMS from '../pages/ConfirmSMS'
import GoalSelection from '../pages/GoalSelection'
import PersonalInfos from '../pages/PersonalInfos'
import AddressInfos from '../pages/AddressInfos'
import Beneficiary from '../pages/Beneficiary'
import ParticipantsList from '../pages/ParticipantsList'
import PpeFatca from '../pages/PpeFatca'
import LGPD from '../pages/LGPD'
import ConfirmOwnership from '../pages/ConfirmOwnership'
import Conclusion from '../pages/Conclusion'
import End from '../pages/End'
import Erro from '../pages/Erro'
import NotFound from '../pages/NotFound'

import usePersistedState from '../hooks/usePersistedState'
import clientConfig from '../services/clientConfig'
import { ConfigData } from '../utils/interfaces'

const Routes: React.FC = () => {
  const { codCliente, tipo, plano } = clientConfig()

  const [configData, setConfigData] = usePersistedState<ConfigData>(
    'configData',
    {} as ConfigData,
  )
  useEffect(() => {
    setConfigData({
      ...configData,
      codCliente,
      tipo,
      plano,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Switch>
      <Route path="/" exact component={BemVindo} />
      <Route path="/contribution" exact component={Contribution} />
      <Route path="/simulation" component={Simulation} />
      <Route path="/type-person" exact component={TypePerson} />
      <Route path="/register" exact component={Register} />
      <Route path="/register/confirm-sms" component={ConfirmSMS} />
      <Route path="/goal-selection" component={GoalSelection} />
      <Route path="/personal-infos" component={PersonalInfos} />
      <Route path="/address-infos" component={AddressInfos} />
      <Route path="/beneficiary" component={Beneficiary} />
      <Route path="/participants-list" component={ParticipantsList} />
      <Route path="/ppe-fatca" component={PpeFatca} />
      <Route path="/lgpd" component={LGPD} />
      <Route path="/confirm-ownership" component={ConfirmOwnership} />
      <Route path="/conclusion" component={Conclusion} />
      <Route path="/end" component={End} />
      <Route path="/erro" component={Erro} />
      <Route path="*" component={NotFound} />
    </Switch>
  )
}

export default Routes
