/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Base64 } from 'js-base64'
import * as Yup from 'yup'
import { FiUser, FiSmartphone, FiMail } from 'react-icons/fi'
import getValidationErrors from '../../utils/getValidationErrors'
import usePersistedState from '../../hooks/usePersistedState'
import api from '../../services/api'
import InputHidden from '../../components/InputHidden'
import Button from '../../components/Button'
import Header from '../../components/Header'
import Input from '../../components/Input'
import { Container, RadioButton, BtnContato, Content, BtnModal } from './styles'
import { UserData, ConfigData, ErroProps } from '../../utils/interfaces'
import ModalBox from '../../components/Modal'
import apiRDStation from '../../services/apiRDStation'

const Register: React.FC = () => {
  const [userData, setUserData] = usePersistedState<UserData>(
    'userData',
    {} as UserData,
  )
  const [configData, setConfigData] = usePersistedState<ConfigData>(
    'configData',
    {} as ConfigData,
  )
  const [, setErroProps] = usePersistedState<ErroProps>(
    'erroProps',
    {} as ErroProps,
  )
  const [tipoContato, setTipoContato] = useState('S')
  const [clicado, setClicado] = useState(false)
  const history = useHistory()
  const formRef = useRef<FormHandles>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [txtModal, setTxtModal] = useState('')

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          name: Yup.string()
            .required('Seu nome é obrigatório.')
            .matches(/\s/g, 'Digite o nome completo')
            .min(5, 'Digite o nome completo'),
          tipContato: Yup.string().required(),
          email: Yup.string()
            .required('E-mail pessoal é obrigatório')
            .email('Digite um e-mail válido'),
          phone: Yup.string()
            .max(15, 'Telefone inválido')
            .min(14, 'Telefone inválido')
            .required('Celular é obrigatório'),
        })

        await schema.validate(data, { abortEarly: false })

        setClicado(true)

        await setUserData({
          ...userData,
          name: data.name,
          email: data.email,
          phone: data.phone,
        })

        const parametros = Base64.encode(
          `{"versao":"${configData.tipo}",
            "plano": "${configData.plano}",
            "cliente":"${configData.codCliente}",
            "cpf":"",
            "email":"${data.email === undefined ? '' : data.email}",
            "telefone":"${
              data.phone === undefined
                ? ''
                : `55${data.phone
                    .replace('(', '')
                    .replace(') ', '')
                    .replace('-', '')}`
            }",
            "admissao":"",
            "envio":"${tipoContato}"}`,
        )

        const parametrosFinal = Base64.encode(parametros)

        await api
          .get(`wsAutenticacao.rule?sys=ADZ&Entrada=${parametrosFinal}`)
          .then(res => {
            setConfigData({ ...configData, token: res.data.token, tipoContato })
            apiRDStation('/register', data.name, data.email, data.phone)
            history.push('/register/confirm-sms')
          })
          .catch(res => {
            if (res.message === 'Network Error') {
              setErroProps({
                title: res.message,
                description: 'Falha na conexão como servidor',
              })
              setClicado(false)
              history.push('/erro')
            } else if (res.message === 'Request failed with status code 500') {
              setErroProps({
                title: 'Erro interno no servidor',
                description: res.message,
              })
              setClicado(false)
              history.push('/erro')
            } else if (res.message === 'Request failed with status code 404') {
              setTxtModal(
                'Não localizamos os seus dados na base cadastral. Por favor, confira as informações digitadas ou entre em contato com a área de atendimento.',
              )
              setIsModalOpen(true)
              setClicado(false)
            } else if (res.message === 'Request failed with status code 401') {
              setTxtModal('Você já é participante.')
              setIsModalOpen(true)
              setClicado(false)
            } else {
              setTxtModal('Ops, algo deu errado. Tente novamente mais tarde.')
              setIsModalOpen(true)
              setClicado(false)
            }
          })
      } catch (err) {
        formRef.current?.setErrors(getValidationErrors(err))
      }
    },
    [
      configData,
      history,
      setConfigData,
      setErroProps,
      setUserData,
      tipoContato,
      userData,
    ],
  )
  // 9308263d-2b0f-43ce-aa52-caa83ffaaab4
  // 83bf426a967845bc849996846360e018

  // const testeRD = useCallback(async () => {
  //   const options = {
  //     method: 'POST',
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json',
  //       Authorization: 'Bearer',
  //     },
  //     body: JSON.stringify({
  //       client_id: '9308263d-2b0f-43ce-aa52-caa83ffaaab4',
  //       client_secret: '83bf426a967845bc849996846360e018',
  //       code: 'cf576c05fccf76e7fac78a1ea4ce7473',
  //     }),
  //   }
  //   fetch(`https://api.rd.services/auth/token`, options)
  //     .then(response => response.json())
  //     .then(response => console.log(response))
  //     .catch(err => console.error(err))
  // }, [])

  const handleConfirmar = useCallback(event => {
    event.preventDefault()
    formRef.current?.submitForm()
  }, [])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <strong>
            Informe os dados do titular que está fazendo a adesão ao Plano
            Família:
          </strong>
          <Form ref={formRef} onSubmit={handleSubmit} initialData={userData}>
            <Input name="name" placeholder="Nome Completo" icon={FiUser} />
            <Input
              name="phone"
              mask="phone"
              prefix="+55 | "
              placeholder="Celular com DDD"
              icon={FiSmartphone}
            />
            <Input
              icon={FiMail}
              name="email"
              id="email"
              type="email"
              placeholder="E-mail"
              maxLength={150}
            />
            <RadioButton>
              <label>Enviar código de confirmação por: </label>
              <div>
                <BtnContato
                  type="button"
                  isActive={tipoContato === 'S'}
                  onClick={() => setTipoContato('S')}
                >
                  SMS
                </BtnContato>
                <BtnContato
                  type="button"
                  isActive={tipoContato === 'E'}
                  onClick={() => setTipoContato('E')}
                >
                  E-mail
                </BtnContato>
              </div>
            </RadioButton>
            <InputHidden name="tipContato" value={tipoContato} type="hidden" />
          </Form>
        </Content>

        <Button
          type="submit"
          color="orange"
          onClick={handleConfirmar}
          disabled={clicado}
        >
          Confirmar
        </Button>
        {/* <Button type="button" color="pink" onClick={testeRD}>
          teste
        </Button> */}
        <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          {txtModal}
          <BtnModal isActive onClick={() => handleCloseModal()}>
            Ok
          </BtnModal>
        </ModalBox>
      </Container>
    </>
  )
}

export default Register
