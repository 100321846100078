/* eslint-disable no-nested-ternary */
import React, { useCallback, useRef, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import Switch from 'react-switch'
import Modal from 'react-modal'
import * as Yup from 'yup'

import CircularProgress from '@material-ui/core/CircularProgress'
import { FaRegMoneyBillAlt } from 'react-icons/fa'
import { AiOutlineBank } from 'react-icons/ai'
import { FiUser, FiArrowRight, FiX } from 'react-icons/fi'
import { MdAccountBalanceWallet } from 'react-icons/md'
import getValidationErrors from '../../utils/getValidationErrors'
import usePersistedState from '../../hooks/usePersistedState'
import InputSelect from '../../components/InputSelect'
import Header from '../../components/Header'
import Button from '../../components/Button'
import Input from '../../components/Input'
import { ValorMascarar } from '../../utils/masks'

import { BtnVoltar, Container, Content } from './styles'
import { UserData, UserDetails, UserAccount } from '../../utils/interfaces'
import apiRDStation from '../../services/apiRDStation'

interface IPaymentOption {
  label: string,
  value: string
}

const ConfirmOwnership: React.FC = () => {
  const [personType] = usePersistedState('tipoPessoa', 'F')
  const [userData, setUserData] = usePersistedState<UserData>(
    'userData',
    {} as UserData,
  )
  const [userAccount, setUserAccount] = usePersistedState<UserAccount>(
    'userAccount',
    {} as UserAccount,
  )
  const [userDetails, setUserDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [paymentOptions, setPaymentOptions] = useState<Array<IPaymentOption>>([
    { label: 'Boleto', value: 'B' },
    { label: 'Débito em folha', value: 'F' }
  ])
  const [PaymentType, setPaymentType] = useState('B')
  const [authorizeTitularPayment, setAuthorizeTitularPayment] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [aguarde] = useState(false)
  const [timer, setTimer] = useState(0)
  const [reSend, setReSend] = useState(true)
  const [bank, setBank] = useState({
    label: `${userAccount.dcrBanco ? userAccount.dcrBanco : ''}`,
    value: `${userAccount.banco ? userAccount.banco : ''}`,
  })
  const history = useHistory()

  const formRef = useRef<FormHandles>(null)

  const toggleAuthorizeTitularPayment = useCallback(() => {
    setAuthorizeTitularPayment(!authorizeTitularPayment)
  }, [authorizeTitularPayment, setAuthorizeTitularPayment])

  const handleSubmit = useCallback(
    async data => {
      try {
        await setUserData({
          ...userData,
          name: data.name,
        })
        await setUserAccount({
          ...userAccount,
          paymentType: data.paymentType,
          dcrBanco: data.paymentType === 'D' ? bank.label : '',
          banco: data.paymentType === 'D' ? parseInt(bank.value, 10) : 0,
          conta: data.paymentType === 'D' ? data.account : '',
          agencia: data.paymentType === 'D' ? data.agency * 1 : 0,
        })
        await setUserDetails({
          ...userDetails,
          contribuicaoBasica: parseFloat(
            data.contribution
              .replaceAll('.', '')
              .replace(',', '.')
              .replace('R$ ', ''),
          ),
          paymentDay: data.paymentDay === '' ? 'B' : data.paymentDay,
        })

        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          name: Yup.string()
            .required('Seu nome é obrigatório.')
            .min(3, 'Digite o nome completo')
            .matches(/\s/g, 'Digite o nome completo'),
          contribution: Yup.string().required(
            'Informe o valor do aporte mensal',
          ),
          paymentDay: Yup.string().when('paymentType', {
            is: 'B',
            then: Yup.string().required('Selecione uma opção')
          }),
          paymentType: Yup.string().required('Selecione uma opção'),
          bank: Yup.string().when('paymentType', {
            is: 'D',
            then: Yup.string().required('Selecione um banco'),
          }),
          agency: Yup.string()
            .max(15)
            .when('paymentType', {
              is: 'D',
              then: Yup.string().required('Informe uma agência'),
            }),
          account: Yup.string().when('paymentType', {
            is: 'D',
            then: Yup.string().required('Informe uma conta'),
          }),
        })

        await schema.validate(data, { abortEarly: false })

        apiRDStation(
          '/comfirm-ownership',
          userData.name,
          userData.email,
          userData.phone,
        )
        history.push('/conclusion')
      } catch (err) {
        formRef.current?.setErrors(getValidationErrors(err as Yup.ValidationError))
      }
    },
    [
      setUserData,
      userData,
      setUserAccount,
      userAccount,
      bank,
      setUserDetails,
      userDetails,
      history,
    ],
  )

  async function handlepayment(option: any) {
    setPaymentType(option.value)
  }

  const handleChangeBanco = useCallback(e => {
    const t = e
    setBank(t)
  }, [])

  const handleChangeValues = useCallback(() => {
    history.push('/result-simulation')
  }, [history])

  const debtOption = { label: 'Débito em folha', value: 'F' }
  useEffect(() => {
    const countTimer = (): void => {
      if (timer > 1) {
        setTimer(timer - 1)
      } else if (timer === 1) {
        setTimer(timer - 1)
        setReSend(true)
      }
    }
    const interval = setInterval(countTimer, 1000)
    return () => clearInterval(interval)
  }, [timer])

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  return (
    <>
      <Header />
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            name: userData.name,
            contribution: ValorMascarar(
              userDetails.contribuicaoBasica.toFixed(2).toString(),
            ),
            agency: userAccount.agencia,
            account: userAccount.conta,
          }}
        >
          <Content>
            <strong>Confirme seus dados:</strong>
            <Input placeholder="Nome completo" name="name" icon={FiUser} />
            <Input
              type="text"
              prefix="R$"
              name="contribution"
              mask="currency"
              icon={FaRegMoneyBillAlt}
              placeholder="Aporte mensal"
              disabled={aguarde}
            />
            <div>
              <InputSelect
                name="paymentType"
                defaultValue={{
                  label:
                    userAccount.paymentType === 'F'
                      ? 'Débito em folha'
                      : 'Boleto',
                  value: PaymentType,
                }}
                options={paymentOptions}
                placeholder="Forma de pagamento da contribuição"
                onChange={option => {
                  handlepayment(option)
                }}
              />
            </div>
            {
              PaymentType === 'B' &&
              <InputSelect
                name="paymentDay"
                defaultValue={{
                  label: userDetails.paymentDay,
                  value: userDetails.paymentDay,
                }}
                options={[
                  { label: '5', value: 5 },
                  { label: '10', value: 10 },
                  { label: '15', value: 15 },
                  { label: '20', value: 20 },
                  { label: '25', value: 25 },
                ]}
                placeholder="Melhor dia para pagamento da contribuição"
              />
            }


            {PaymentType === 'D' ? (
              <div>
                <InputSelect
                  name="bank"
                  defaultValue={{
                    label: userAccount.dcrBanco,
                    value: userAccount.banco,
                  }}
                  options={[
                    { label: 'Banco do Brasil', value: '1' },
                    { label: 'Banco Bradesco', value: '237' },
                    { label: 'Caixa Econômica Federal', value: '104' },
                    { label: 'Banco Itau SA', value: '341' },
                    { label: 'Banco Santander S.A.', value: '33' },
                    {
                      label: 'HSBC Bank Brasil S.A. - Banco Múltiplo',
                      value: '399',
                    },
                    { label: 'Banco Mercantil do Brasil S.A.', value: '389' },
                  ]}
                  placeholder="Banco"
                  onChange={e => handleChangeBanco(e)}
                />
                <Input
                  name="agency"
                  placeholder="Agência (sem dígito)"
                  type="text"
                  maxLength={5}
                  icon={AiOutlineBank}
                />
                <Input
                  name="account"
                  placeholder="Conta com dígito"
                  mask="conta"
                  maxLength={20}
                  icon={MdAccountBalanceWallet}
                />
              </div>
            ) : (
              <div />
            )}

            <div className="authorization">
              <span>
                Autorizo a cobrança mensal recorrente do valor simulado
              </span>
              <Switch
                onChange={toggleAuthorizeTitularPayment}
                checked={authorizeTitularPayment}
                checkedIcon={false}
                uncheckedIcon={false}
                height={22}
                width={44}
                handleDiameter={18}
                offColor="#FBADB4"
                offHandleColor="#fff"
                onHandleColor="#fff"
                onColor="#31D19E"
              />
            </div>

            <Modal
              isOpen={isModalOpen}
              onRequestClose={handleCloseModal}
              overlayClassName="react-modal-overlay"
              className="react-modal-content"
            >
              <FiX onClick={handleCloseModal} />
              <strong>Regime de tributação</strong>
              <div>
                <h3>Regime Progressivo</h3>
                <p>
                  A renda recebida do plano é tributada de acordo com as
                  alíquotas previstas, a mesma utilizada para a tributação dos
                  salários. No momento da Declaração Anual de Impost ode Renda o
                  contribuinte efetuará os devidos acertos.
                </p>
              </div>
              <div>
                <h3>Regime Regressivo</h3>
                <p>
                  O percentual de desconto do Imposto de Renda sobre a renda
                  diminui com o tempo de permanência no plano. A tributação é
                  definitiva. Não permite ajuste na Declaração Anual de IR.
                </p>
              </div>
              <small>
                OBS.: a tabela progressiva pode ser alterada para regressiva até
                o último dia útil do mês seguinte à adesão.
              </small>
            </Modal>
          </Content>
        </Form>

        <Button
          type="button"
          color="orange"
          disabled={!authorizeTitularPayment || !reSend}
          onClick={() => formRef.current?.submitForm()}
        >
          {!aguarde ? (
            !reSend ? (
              <>
                <span>
                  Tente novamente em {timer < 10 ? `0${timer}` : timer} segundos
                </span>
              </>
            ) : (
              <>
                <span>Seguir para a adesão</span>
                <div>
                  <FiArrowRight />
                </div>
              </>
            )
          ) : (
            <>
              <CircularProgress color="inherit" />
              <span>Aguarde</span>
            </>
          )}
        </Button>
        <BtnVoltar type="button" onClick={() => history.goBack()}>
          &lt; Anterior
        </BtnVoltar>
      </Container>
    </>
  )
}

export default ConfirmOwnership
