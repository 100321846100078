/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useCallback, useState } from 'react'
import { FiMail, FiUser, FiSmartphone } from 'react-icons/fi'
import { MdSecurity } from 'react-icons/md'
import { BiFemale, BiCake, BiIdCard } from 'react-icons/bi'
import * as Yup from 'yup'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import { useHistory } from 'react-router-dom'
import { Collapse } from '@material-ui/core'
import moment from 'moment'
import { Base64 } from 'js-base64'
import {
  Container,
  Content,
  BtnVoltar,
  Line,
  RadioButton,
  BtnToggle,
  BtnModal,
} from './styles'
import Header from '../../components/Header'
import usePersistedState from '../../hooks/usePersistedState'
import Input from '../../components/Input'
import Button from '../../components/Button'
import InputHidden from '../../components/InputHidden'
import getValidationErrors from '../../utils/getValidationErrors'

import {
  UserData,
  FinancialIndicationData,
  LegalIndicationData,
  ConfigData,
  ErroProps,
} from '../../utils/interfaces'
import validaCPF from '../../utils/validaCPF'
import calculaIdade from '../../utils/calculaIdade'
import api from '../../services/api'
import ModalBox from '../../components/Modal'
import apiRDStation from '../../services/apiRDStation'

const PersonalInfos: React.FC = () => {
  const [configData] = usePersistedState<ConfigData>(
    'configData',
    {} as ConfigData,
  )

  const [userData, setUserData] = usePersistedState<UserData>(
    'userData',
    {} as UserData,
  )

  const [
    financialIndicationData,
    setFinancialIndicationData,
  ] = usePersistedState<FinancialIndicationData>(
    'financialIndicationData',
    {} as FinancialIndicationData,
  )

  const [legalIndicationData, setLegalIndicationData] = usePersistedState<
    LegalIndicationData
  >('legalIndicationData', {} as LegalIndicationData)

  const [menorIdade, setMenorIdade] = usePersistedState('menorIdade', false)
  const [respLegal, setRespLegal] = usePersistedState('respLegal', false)
  const [respFinanceiro, setRespFinanceiro] = usePersistedState(
    'respFinanceiro',
    false,
  )
  const [respLegalEqualFin, setRespLegalEqualFin] = usePersistedState(
    'respLegalEqualFin',
    'N',
  )

  const [, setErroProps] = usePersistedState<ErroProps>(
    'erroProps',
    {} as ErroProps,
  )

  const [isModalOpen, setIsModalOpen] = useState(false)

  const history = useHistory()
  const formRef = useRef<FormHandles>(null)

  const handleClick = useCallback(() => {
    formRef.current?.submitForm()
  }, [])

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const handleSubmit = useCallback(
    async data => {
      try {
        const aa = data.birthdate
        const dia = aa.split('/')[0]
        const mes = aa.split('/')[1]
        const ano = aa.split('/')[2]
        const dataForm = `${ano}-${`0${mes}`.slice(-2)}-${`0${dia}`.slice(-2)}`

        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          name: Yup.string()
            .required('Seu nome é obrigatório.')
            .matches(/\s/g, 'Digite o nome completo')
            .min(5, 'Digite o nome completo'),
          email: Yup.string()
            .required('E-mail é obrigatório')
            .email('Digite um e-mail válido'),
          phone: Yup.string()
            .max(15, 'Telefone inválido')
            .required('Celular obrigatório'),
          cpf: Yup.string()
            .required('CPF obrigatório')
            .test(
              '',
              'CPF inválido',
              () =>
                validaCPF(data.cpf.replaceAll('.', '').replace('-', '')) ||
                data.cpf === '',
            ),
          birthdate: Yup.string()
            .required('Data de nascimento obrigatória')
            .test(
              '',
              'A data de nascimento não pode ser maior que hoje.',
              () =>
                moment() >
                  moment(data.birthdate.split('/').reverse().join('-')) ||
                data.birthdate === '',
            )
            .test(
              '',
              'Data de nascimento inválida',
              () =>
                moment(
                  data.birthdate.split('/').reverse().join('-'),
                ).isValid() || data.birthdate === '',
            )
            .test(
              '',
              'Data de nascimento inválida',
              () =>
                calculaIdade(data.birthdate.split('/').reverse().join('-')) <=
                  115 || data.birthdate === '',
            ),
          parental: Yup.string()
            .required('O nome da mãe é obrigatório.')
            .matches(/\s/g, 'Digite o nome completo')
            .min(5, 'Digite o nome completo'),
          nomeResponsavelLeg: Yup.string().when('responsavelLeg', {
            is: 'S',
            then: Yup.string()
              .required('Nome do responsável legal é obrigatório')
              .matches(/\s/g, 'Digite o nome completo'),
          }),
          cpfResponsavelLeg: Yup.string().when('responsavelLeg', {
            is: 'S',
            then: Yup.string()
              .required('CPF obrigatório')
              .test(
                '',
                'CPF inválido',
                () =>
                  validaCPF(
                    data.cpfResponsavelLeg.replaceAll('.', '').replace('-', ''),
                  ) || data.cpfResponsavelLeg === '',
              )
              .test(
                '',
                'O CPF não pode ser igual ao do titular',
                () =>
                  data.cpfResponsavelLeg
                    .replaceAll('.', '')
                    .replace('-', '') !==
                    data.cpf.replaceAll('.', '').replace('-', '') ||
                  data.cpfResponsavelLeg === '',
              ),
          }),
          birthdateResponsavelLeg: Yup.string().when('responsavelLeg', {
            is: 'S',
            then: Yup.string()
              .required('Data de nascimento obrigatória')
              .test(
                '',
                'Data inválida.',
                () =>
                  moment(
                    data.birthdateResponsavelLeg.split('/').reverse().join('-'),
                  ).isValid() || data.birthdateResponsavelLeg === '',
              )
              .test(
                '',
                'O responsável legal deve ter 18 anos ou mais.',
                () =>
                  calculaIdade(
                    data.birthdateResponsavelLeg.split('/').reverse().join('-'),
                  ) >= 18 || data.birthdateResponsavelLeg === '',
              )
              .test(
                '',
                'Data de nascimento inválida',
                () =>
                  calculaIdade(
                    data.birthdateResponsavelLeg.split('/').reverse().join('-'),
                  ) <= 115 || data.birthdateResponsavelLeg === '',
              ),
          }),
          telResponsavelLeg: Yup.string().when('responsavelLeg', {
            is: 'S',
            then: Yup.string()
              .max(15, 'Telefone inválido')
              .required('Celular obrigatório'),
          }),
          emailResponsavelLeg: Yup.string().when('responsavelLeg', {
            is: 'S',
            then: Yup.string()
              .required('E-mail é obrigatório')
              .email('Digite um e-mail válido'),
          }),

          nomeResponsavelFin: Yup.string().when('responsavelFin', {
            is: 'S',
            then: Yup.string()
              .required('Nome do responsável legal é obrigatório')
              .matches(/\s/g, 'Digite o nome completo'),
          }),
          cpfResponsavelFin: Yup.string().when('responsavelFin', {
            is: 'S',
            then: Yup.string()
              .required('CPF obrigatório')
              .test(
                '',
                'CPF inválido',
                () =>
                  validaCPF(
                    data.cpfResponsavelFin.replaceAll('.', '').replace('-', ''),
                  ) || data.cpfResponsavelFin === '',
              )
              .test(
                '',
                'O CPF não pode ser igual ao do titular',
                () =>
                  data.cpfResponsavelFin
                    .replaceAll('.', '')
                    .replace('-', '') !==
                    data.cpf.replaceAll('.', '').replace('-', '') ||
                  data.cpfResponsavelFin === '',
              ),
          }),
          birthdateResponsavelFin: Yup.string().when('responsavelFin', {
            is: 'S',
            then: Yup.string()
              .required('Data de nascimento obrigatória')
              .test(
                '',
                'Data inválida.',
                () =>
                  moment(
                    data.birthdateResponsavelFin.split('/').reverse().join('-'),
                  ).isValid() || data.birthdateResponsavelFin === '',
              )
              .test(
                '',
                'O responsável legal deve ter 18 anos ou mais.',
                () =>
                  calculaIdade(
                    data.birthdateResponsavelFin.split('/').reverse().join('-'),
                  ) >= 18 || data.birthdateResponsavelFin === '',
              )
              .test(
                '',
                'Data de nascimento inválida',
                () =>
                  calculaIdade(
                    data.birthdateResponsavelFin.split('/').reverse().join('-'),
                  ) <= 115 || data.birthdateResponsavelFin === '',
              ),
          }),
          telResponsavelFin: Yup.string().when('responsavelFin', {
            is: 'S',
            then: Yup.string()
              .max(15, 'Telefone inválido')
              .required('Celular obrigatório'),
          }),
          emailResponsavelFin: Yup.string().when('responsavelFin', {
            is: 'S',
            then: Yup.string()
              .required('E-mail é obrigatório')
              .email('Digite um e-mail válido'),
          }),
        })

        await schema.validate(data, { abortEarly: false })

        await setUserData({
          ...userData,
          name: data.name,
          phone: data.phone,
          email: data.email,
          cpf: data.cpf,
          birthdate: dataForm,
          parental: data.parental,
        })

        await setLegalIndicationData({
          ...legalIndicationData,
          nomeResponsavelLeg: data.nomeResponsavelLeg,
          cpfResponsavelLeg: data.cpfResponsavelLeg,
          birthdateResponsavelLeg: data.birthdateResponsavelLeg
            .split('/')
            .reverse()
            .join('-'),
          telResponsavelLeg: data.telResponsavelLeg,
          emailResponsavelLeg: data.emailResponsavelLeg,
        })

        if (respLegalEqualFin === 'S' && respFinanceiro) {
          await setFinancialIndicationData({
            ...financialIndicationData,
            nomeResponsavelFin: legalIndicationData.nomeResponsavelLeg,
            cpfResponsavelFin: legalIndicationData.cpfResponsavelLeg,
            birthdateResponsavelFin: legalIndicationData.birthdateResponsavelLeg
              .split('/')
              .reverse()
              .join('-'),
            telResponsavelFin: legalIndicationData.telResponsavelLeg,
            emailResponsavelFin: legalIndicationData.emailResponsavelLeg,
          })
        } else if (respFinanceiro) {
          await setFinancialIndicationData({
            ...financialIndicationData,
            nomeResponsavelFin: data.nomeResponsavelFin,
            cpfResponsavelFin: data.cpfResponsavelFin,
            birthdateResponsavelFin: data.birthdateResponsavelFin
              .split('/')
              .reverse()
              .join('-'),
            telResponsavelFin: data.telResponsavelFin,
            emailResponsavelFin: data.emailResponsavelFin,
          })
        }

        const parametros = Base64.encode(
          `{
            "versao": "${configData.tipo}",
            "cliente":"${configData.codCliente}",
            "cpf":"${data.cpf.replaceAll('.', '').replace('-', '')}",
            "nascimento": "${data.birthdate.split('/').reverse().join('-')}"
          }`,
        )

        const parametrosFinal = Base64.encode(parametros)

        await api
          .get(`wsValidaCpf.rule?sys=ADZ&Entrada=${parametrosFinal}`)
          .then(res => {
            if (res.data.novoUsuario === 'N') {
              setIsModalOpen(true)
            } else {
              apiRDStation('/personal-infos', data.name, data.email, data.phone)
              history.push('/address-infos')
            }
          })
          .catch(res => {
            setErroProps({
              title: 'Erro interno no servidor',
              description: res.message,
            })
            history.push('/erro')
          })
      } catch (err) {
        formRef.current?.setErrors(getValidationErrors(err))
      }
    },
    [
      configData,
      financialIndicationData,
      history,
      legalIndicationData,
      respFinanceiro,
      respLegalEqualFin,
      setErroProps,
      setFinancialIndicationData,
      setLegalIndicationData,
      setUserData,
      userData,
    ],
  )

  const handleDataNascimento = useCallback(
    e => {
      const idade = calculaIdade(e.split('/').reverse().join('-'))
      if (idade < 18) {
        setRespLegal(true)
        setRespFinanceiro(true)
        setMenorIdade(true)
      } else {
        setMenorIdade(false)
      }
    },
    [setMenorIdade, setRespFinanceiro, setRespLegal],
  )

  const toggleRespLegalEqualFin = useCallback(
    isEqual => {
      setRespLegalEqualFin(isEqual)
      if (isEqual === 'S') {
        setFinancialIndicationData({
          ...financialIndicationData,
          nomeResponsavelFin: legalIndicationData.nomeResponsavelLeg,
          cpfResponsavelFin: legalIndicationData.cpfResponsavelLeg,
          birthdateResponsavelFin: legalIndicationData.birthdateResponsavelLeg,
          telResponsavelFin: legalIndicationData.telResponsavelLeg,
          emailResponsavelFin: legalIndicationData.emailResponsavelLeg,
        })
      } else {
        setFinancialIndicationData({
          ...financialIndicationData,
          nomeResponsavelFin: '',
          cpfResponsavelFin: '',
          birthdateResponsavelFin: '',
          telResponsavelFin: '',
          emailResponsavelFin: '',
        })
      }
    },
    [
      financialIndicationData,
      legalIndicationData.birthdateResponsavelLeg,
      legalIndicationData.cpfResponsavelLeg,
      legalIndicationData.emailResponsavelLeg,
      legalIndicationData.nomeResponsavelLeg,
      legalIndicationData.telResponsavelLeg,
      setFinancialIndicationData,
      setRespLegalEqualFin,
    ],
  )

  return (
    <>
      <Header />
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            name: userData.name,
            email: userData.email,
            phone: userData.phone,
            cpf: userData.cpf,
            birthdate:
              userData.birthdate === undefined
                ? ''
                : userData.birthdate.split('-').reverse().join('/'),
            parental: userData.parental,
            nomeResponsavelLeg: legalIndicationData.nomeResponsavelLeg,
            cpfResponsavelLeg: legalIndicationData.cpfResponsavelLeg,
            birthdateResponsavelLeg:
              legalIndicationData.birthdateResponsavelLeg === undefined
                ? ''
                : legalIndicationData.birthdateResponsavelLeg
                    .split('-')
                    .reverse()
                    .join('/'),
            telResponsavelLeg: legalIndicationData.telResponsavelLeg,
            emailResponsavelLeg: legalIndicationData.emailResponsavelLeg,
            nomeResponsavelFin: financialIndicationData.nomeResponsavelFin,
            cpfResponsavelFin: financialIndicationData.cpfResponsavelFin,
            birthdateResponsavelFin:
              financialIndicationData.birthdateResponsavelFin === undefined
                ? ''
                : financialIndicationData.birthdateResponsavelFin
                    .split('-')
                    .reverse()
                    .join('/'),
            telResponsavelFin: financialIndicationData.telResponsavelFin,
            emailResponsavelFin: financialIndicationData.emailResponsavelFin,
          }}
        >
          <Content>
            <strong>
              Queremos saber mais sobre você,
              {/* {userData.name.split(' ')[0]}: */}
            </strong>
            <InputHidden name="contribution" type="hidden" />
            <Input name="name" placeholder="Nome Completo" icon={FiUser} />
            <Input
              name="phone"
              mask="phone"
              prefix="+55 | "
              placeholder="Seu celular com DDD"
              icon={FiSmartphone}
            />
            <Input
              icon={FiMail}
              name="email"
              type="email"
              placeholder="Seu e-mail pessoal"
            />
            <Input
              placeholder="Digite seu CPF"
              name="cpf"
              icon={MdSecurity}
              type="tel"
              mask="cpf"
              maxLength={11}
            />
            <Input
              icon={BiCake}
              name="birthdate"
              mask="date"
              placeholder="Data de nascimento"
              onBlur={e => handleDataNascimento(e.target.value)}
            />
            <Input
              icon={BiFemale}
              name="parental"
              placeholder="Nome Completo da Mãe"
            />
            <Line />
            <RadioButton>
              <label>Possui responsável legal?</label>
              <div>
                <BtnToggle
                  type="button"
                  isActive={respLegal}
                  onClick={() => setRespLegal(true)}
                  disabled={menorIdade}
                >
                  Sim
                </BtnToggle>
                <BtnToggle
                  type="button"
                  isActive={!respLegal}
                  onClick={() => setRespLegal(false)}
                  disabled={menorIdade}
                >
                  Não
                </BtnToggle>
              </div>
            </RadioButton>

            <Collapse in={respLegal}>
              {/* <strong>Responsável Legal:</strong> */}
              <InputHidden
                type="hidden"
                name="responsavelLeg"
                value={respLegal ? 'S' : 'N'}
              />
              <Input
                placeholder="Nome do responsável legal"
                name="nomeResponsavelLeg"
                icon={FiUser}
                onChange={e => {
                  setLegalIndicationData({
                    ...legalIndicationData,
                    nomeResponsavelLeg: e.target.value,
                  })
                }}
              />
              <Input
                placeholder="CPF do responsável legal"
                name="cpfResponsavelLeg"
                icon={MdSecurity}
                mask="cpf"
                maxLength={14}
                onChange={e => {
                  setLegalIndicationData({
                    ...legalIndicationData,
                    cpfResponsavelLeg: e.target.value,
                  })
                }}
              />
              <Input
                icon={BiCake}
                name="birthdateResponsavelLeg"
                mask="date"
                placeholder="Data de nascimento do responsável legal"
                onChange={e => {
                  setLegalIndicationData({
                    ...legalIndicationData,
                    birthdateResponsavelLeg: e.target.value,
                  })
                }}
              />
              <Input
                placeholder="Telefone do responsável legal"
                name="telResponsavelLeg"
                type="tel"
                mask="phone"
                icon={FiSmartphone}
                onChange={e => {
                  setLegalIndicationData({
                    ...legalIndicationData,
                    telResponsavelLeg: e.target.value,
                  })
                }}
              />
              <Input
                placeholder="E-mail do responsável legal"
                name="emailResponsavelLeg"
                type="mail"
                icon={FiMail}
                onChange={e => {
                  setLegalIndicationData({
                    ...legalIndicationData,
                    emailResponsavelLeg: e.target.value,
                  })
                }}
              />
            </Collapse>
            <Line />
            <RadioButton>
              <label>Possui responsável financeiro?</label>
              <div>
                <BtnToggle
                  type="button"
                  isActive={respFinanceiro}
                  onClick={() => setRespFinanceiro(true)}
                  disabled={menorIdade}
                >
                  Sim
                </BtnToggle>
                <BtnToggle
                  type="button"
                  isActive={!respFinanceiro}
                  onClick={() => setRespFinanceiro(false)}
                  disabled={menorIdade}
                >
                  Não
                </BtnToggle>
              </div>
            </RadioButton>
            <Collapse in={respFinanceiro}>
              {respLegal ? (
                <RadioButton>
                  <label>
                    Responsável financeiro e responsável legal são a mesma
                    pessoa?
                  </label>
                  <div>
                    <BtnToggle
                      type="button"
                      isActive={respLegalEqualFin === 'S'}
                      onClick={() => toggleRespLegalEqualFin('S')}
                    >
                      Sim
                    </BtnToggle>
                    <BtnToggle
                      type="button"
                      isActive={respLegalEqualFin === 'N'}
                      onClick={() => toggleRespLegalEqualFin('N')}
                    >
                      Não
                    </BtnToggle>
                  </div>
                </RadioButton>
              ) : (
                <></>
              )}
              {/* <strong>Responsável Financeiro:</strong> */}

              <Collapse in={respLegalEqualFin !== 'S'}>
                <InputHidden
                  type="hidden"
                  name="responsavelFin"
                  value={respFinanceiro ? 'S' : 'N'}
                />
                <Input
                  placeholder="Nome do responsável financeiro"
                  name="nomeResponsavelFin"
                  icon={FiUser}
                  value={financialIndicationData.nomeResponsavelFin}
                  onChange={e => {
                    setFinancialIndicationData({
                      ...financialIndicationData,
                      nomeResponsavelFin: e.target.value,
                    })
                  }}
                />
                <Input
                  placeholder="CPF do responsável financeiro"
                  name="cpfResponsavelFin"
                  icon={MdSecurity}
                  mask="cpf"
                  maxLength={14}
                  value={financialIndicationData.cpfResponsavelFin}
                  onChange={e => {
                    setFinancialIndicationData({
                      ...financialIndicationData,
                      cpfResponsavelFin: e.target.value,
                    })
                  }}
                />
                <Input
                  icon={BiCake}
                  name="birthdateResponsavelFin"
                  mask="date"
                  placeholder="Data de nascimento do responsável financeiro"
                  value={financialIndicationData.birthdateResponsavelFin}
                  onChange={e => {
                    setFinancialIndicationData({
                      ...financialIndicationData,
                      birthdateResponsavelFin: e.target.value,
                    })
                  }}
                />
                <Input
                  placeholder="Telefone do responsável financeiro"
                  name="telResponsavelFin"
                  type="tel"
                  mask="phone"
                  icon={FiSmartphone}
                  value={financialIndicationData.telResponsavelFin}
                  onChange={e => {
                    setFinancialIndicationData({
                      ...financialIndicationData,
                      telResponsavelFin: e.target.value,
                    })
                  }}
                />
                <Input
                  placeholder="E-mail do responsável financeiro"
                  name="emailResponsavelFin"
                  type="mail"
                  icon={FiMail}
                  value={financialIndicationData.emailResponsavelFin}
                  onChange={e => {
                    setFinancialIndicationData({
                      ...financialIndicationData,
                      emailResponsavelFin: e.target.value,
                    })
                  }}
                />
              </Collapse>
            </Collapse>
          </Content>
        </Form>

        <Button type="submit" color="orange" onClick={handleClick}>
          Avançar
        </Button>
        <BtnVoltar type="button" onClick={() => history.goBack()}>
          &lt; Anterior
        </BtnVoltar>

        <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          <div className="confirmations">
            <p>
              Você confirma que o CPF {userData.cpf} pertence à {userData.name}?
            </p>
            <span>
              *Caso já exista um registro para este CPF e data de nascimento na
              base de dados, o cadastro será atualizado.
            </span>

            <div>
              <BtnModal
                isActive
                onClick={() => {
                  apiRDStation(
                    '/personal-infos',
                    userData.name,
                    userData.email,
                    userData.phone,
                  )
                  history.push('/address-infos')
                  setIsModalOpen(false)
                }}
              >
                Confirmar
              </BtnModal>

              <BtnModal isActive onClick={() => setIsModalOpen(false)}>
                Cancelar
              </BtnModal>
            </div>
          </div>
        </ModalBox>
      </Container>
    </>
  )
}

export default PersonalInfos
