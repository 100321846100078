import styled from 'styled-components'

export const Container = styled.img`
  height: 100%;
  padding: 10px 0;

  @media screen and (max-width: 766px) {
    padding: 5px 0;
  }
`
