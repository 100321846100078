import React, { useRef, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import Axios from 'axios'
import { FiMapPin, FiHome, FiMap } from 'react-icons/fi'
import { AiOutlineFieldNumber } from 'react-icons/ai'
import { BiMapPin, BiBuildings } from 'react-icons/bi'
import { RiCommunityLine } from 'react-icons/ri'
import getValidationErrors from '../../utils/getValidationErrors'
import usePersistedState from '../../hooks/usePersistedState'
import Header from '../../components/Header'
import Button from '../../components/Button'
import Input from '../../components/Input'
import { UserAddress, UserData } from '../../utils/interfaces'
import { BtnVoltar, Container, Content, BtnModal } from './styles'
import ModalBox from '../../components/Modal'
import apiRDStation from '../../services/apiRDStation'
import InputSelect from '../../components/InputSelect'

const AddressInfos: React.FC = () => {
  const [userAddress, setUserAddress] = usePersistedState<UserAddress>(
    'userAddress',
    {} as UserAddress,
  )
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)
  const [, setFlagAddress] = useState(
    !(
      userAddress.cep === undefined ||
      userAddress.cep === ' ' ||
      userAddress.cep === ''
    ),
  )
  const history = useHistory()
  const formRef = useRef<FormHandles>(null)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [txtModal, setTxtModal] = useState('')
  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const listaEstados = [
    { label: 'Acre', value: 'AC' },
    { label: 'Alagoas', value: 'AL' },
    { label: 'Amazonas', value: 'AM' },
    { label: 'Amapá', value: 'AP' },
    { label: 'Bahia', value: 'BA' },
    { label: 'Ceará', value: 'CE' },
    { label: 'Distrito Federal', value: 'DF' },
    { label: 'Espírito Santo', value: 'ES' },
    { label: 'Goias', value: 'GO' },
    { label: 'Maranhão', value: 'MA' },
    { label: 'Minas Gerais', value: 'MG' },
    { label: 'Mato Grosso do Sul', value: 'MS' },
    { label: 'Mato Grosso', value: 'MT' },
    { label: 'Pará', value: 'PA' },
    { label: 'Paraíba', value: 'PB' },
    { label: 'Pernambuco', value: 'PE' },
    { label: 'Piauí', value: 'PI' },
    { label: 'Paraná', value: 'PR' },
    { label: 'Rio de Janeiro', value: 'RJ' },
    { label: 'Rio Grande do Norte', value: 'RN' },
    { label: 'Rondônia', value: 'RO' },
    { label: 'Roraíma', value: 'RR' },
    { label: 'Rio Grande do Sul', value: 'RS' },
    { label: 'Santa Catarina', value: 'SC' },
    { label: 'Sergipe', value: 'SE' },
    { label: 'São Paulo', value: 'SP' },
    { label: 'Tocantins', value: 'TO' },
  ]

  const handleSubmit = useCallback(
    async data => {
      try {
        const schema = Yup.object().shape({
          cep: Yup.string()
            .min(9, 'CEP inválido')
            .max(9, 'CEP inválido')
            .required('CEP obrigatório'),
          logradouro: Yup.string().required('Logradouro obrigatório'),
          bairro: Yup.string().required('Bairro obrigatório'),
          municipio: Yup.string().required('Município obrigatório'),
          numero: Yup.string().required('Número obrigatório'),
          uf: Yup.string().required('UF obrigatório'),
        })

        await schema.validate(data, { abortEarly: false })

        const est = listaEstados.filter(obj => {
          return obj.value === data.uf
        })

        setUserAddress({
          ...userAddress,
          cep: data.cep,
          logradouro: data.logradouro,
          bairro: data.bairro,
          municipio: data.municipio,
          uf: data.uf,
          estado: est[0].label,
          numero: data.numero,
          complemento: data.complemento,
        })
        apiRDStation(
          '/address-infos',
          userData.name,
          userData.email,
          userData.phone,
        )
        history.push('/beneficiary')
      } catch (err) {
        formRef.current?.setErrors(
          getValidationErrors(err as Yup.ValidationError),
        )
      }
    },
    [
      listaEstados,
      setUserAddress,
      userAddress,
      userData.name,
      userData.email,
      userData.phone,
      history,
    ],
  )

  const handleBuscaCep = useCallback(
    async cep => {
      const tamanho = cep.length
      if (tamanho === 9) {
        await Axios.get(
          `https://viacep.com.br/ws/${cep.replace('-', '')}/json/`,
        )
          .then(res => {
            if (res.data.erro === true) {
              setFlagAddress(false)
              setUserAddress({
                ...userAddress,
                cep: '',
                bairro: '',
                logradouro: '',
                municipio: '',
                uf: '',
                estado: '',
              })
              setTxtModal('CEP inválido')
              setIsModalOpen(true)
            } else {
              setFlagAddress(true)
              setUserAddress({
                ...userAddress,
                cep,
                bairro: res.data.bairro,
                logradouro: res.data.logradouro,
                municipio: res.data.localidade,
                uf: res.data.uf,
                estado: res.data.estado,
              })
              // handleChangeUF(res.data.uf)
              document.location.reload()
            }
          })
          .catch(() => {
            setFlagAddress(false)
            setUserAddress({
              ...userAddress,
              cep: '',
              bairro: '',
              logradouro: '',
              municipio: '',
              uf: '',
            })
            setTxtModal('CEP inválido')
            setIsModalOpen(true)
          })
      }
    },
    [setUserAddress, userAddress],
  )

  const handleClick = useCallback(() => {
    formRef.current?.submitForm()
  }, [])

  return (
    <>
      <Header />
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            cep: userAddress.cep,
            numero: userAddress.numero,
            complemento: userAddress.complemento,
          }}
        >
          <Content>
            <strong>Endereço</strong>
            <Input
              name="cep"
              placeholder="CEP"
              mask="cep"
              icon={BiMapPin}
              onChange={e => handleBuscaCep(e.target.value)}
            />
            <Input
              name="logradouro"
              placeholder="Logradouro"
              icon={FiHome}
              value={userAddress.logradouro}
              onChange={e => {
                setUserAddress({
                  ...userAddress,
                  logradouro: e.target.value,
                })
              }}
            />
            <Input
              name="bairro"
              id="bairro"
              placeholder="Bairro"
              icon={RiCommunityLine}
              value={userAddress.bairro}
              onChange={e => {
                setUserAddress({
                  ...userAddress,
                  bairro: e.target.value,
                })
              }}
            />
            <Input
              name="municipio"
              placeholder="Município"
              icon={BiBuildings}
              value={userAddress.municipio}
              onChange={e => {
                setUserAddress({
                  ...userAddress,
                  municipio: e.target.value,
                })
              }}
            />
            <Input
              name="numero"
              placeholder="Número"
              icon={AiOutlineFieldNumber}
            />
            <Input
              name="complemento"
              placeholder="Complemento"
              icon={FiMapPin}
            />
            {/* <Input
              name="uf"
              placeholder="UF"
              value={userAddress.uf}
              icon={FiMap}
            /> */}
            <InputSelect
              options={listaEstados}
              name="uf"
              placeholder="UF"
              value={{ label: userAddress.estado, value: userAddress.uf }}
            />
          </Content>
        </Form>

        <Button type="submit" color="orange" onClick={handleClick}>
          Avançar
        </Button>
        <BtnVoltar type="button" onClick={() => history.goBack()}>
          &lt; Anterior
        </BtnVoltar>
        <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          {txtModal}
          <BtnModal isActive onClick={() => handleCloseModal()}>
            Ok
          </BtnModal>
        </ModalBox>
      </Container>
    </>
  )
}

export default AddressInfos
