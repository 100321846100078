/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Switch from 'react-switch'

// import axios from 'axios'
import usePersistedState from '../../hooks/usePersistedState'

import Header from '../../components/Header'
import Button from '../../components/Button'
import Dots from './components/Dots'

import img1 from '../../assets/Ilustracao_tela1.png'
import img2 from '../../assets/Ilustracao_tela2.png'
import img3 from '../../assets/Ilustracao_tela3.png'
import { Container, DivContent, Content, BtnVoltar } from './styles'
// import { RDStationCredentials } from '../../utils/interfaces'

const BemVindo: React.FC = () => {
  // const [rdStationCredentials, setRDStationCredentials] = usePersistedState<
  //   RDStationCredentials
  // >('rdStationCredentials', {} as RDStationCredentials)

  const [acceptTerms, setAcceptTerms] = usePersistedState('acceptTerms', false)

  const history = useHistory()
  const [quantity] = useState(3)
  const [active, setActive] = useState(0)

  const handleSetProximo = useCallback(() => {
    const newActive = active + 1
    if (newActive < quantity) {
      setActive(newActive)
    }
  }, [active, quantity])

  const handleSetAnterior = useCallback(() => {
    const newActive = active - 1
    if (newActive >= 0) {
      setActive(newActive)
    }
  }, [active])

  const toggleAcceptTerms = useCallback(() => {
    setAcceptTerms(!acceptTerms)
  }, [acceptTerms, setAcceptTerms])

  const handleStart = useCallback(async () => {
    if (acceptTerms) {
      // const header1 = {
      //   method: 'POST',
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json',
      //     Authorization: 'Bearer',
      //   },
      //   body: JSON.stringify({
      //     client_id: '9308263d-2b0f-43ce-aa52-caa83ffaaab4',
      //     client_secret: '83bf426a967845bc849996846360e018',
      //     code: '70e40faf09b0971fde6a8050dc4581b2',
      //   }),
      // }
      // await fetch(`https://api.rd.services/auth/token`, header1)
      //   .then(response => response.json())
      //   .then(response => {
      //     console.log(response)
      //     setRDStationCredentials({
      //       ...rdStationCredentials,
      //       code: response.access_token,
      //       expires_in: response.expires_in,
      //       refresh_token: response.refresh_token,
      //     })
      //   })
      //   .catch(err => console.error(err))

      history.push('/contribution')
    } else {
      console.warn('is necessary accept the terms to continue.')
    }
  }, [acceptTerms, history])

  useEffect(() => {
    localStorage.removeItem('@BRFPrevFamilia:token')
    localStorage.removeItem('@BRFPrevFamilia:tipoCont')
    localStorage.removeItem('@BRFPrevFamilia:goalsSelected')
    localStorage.removeItem('@BRFPrevFamilia:userDetails')
    localStorage.removeItem('@BRFPrevFamilia:receiveTypeSelected')
    localStorage.removeItem('@BRFPrevFamilia:totalRentability')
    localStorage.removeItem('@BRFPrevFamilia:TimeValueYears')
    localStorage.removeItem('@BRFPrevFamilia:PercentualValuePercent')
    localStorage.removeItem('@BRFPrevFamilia:authorizeTitularPayment')
    localStorage.removeItem('@BRFPrevFamilia:userData')
  }, [])

  return (
    <>
      <Header />
      <Container>
        <DivContent>
          <Content isActive={active === 0}>
            <img src={img1} alt="Carteira" />
            <strong>
              Que tal iniciar o planejamento financeiro para realização de seus
              sonhos e de sua família?
            </strong>
            <p>
              Com o Plano Família você e seus familiares garantem um novo modelo
              de investimento para o futuro com a excelência da BRF Previdência.
            </p>
          </Content>

          <Content isActive={active === 1}>
            <img src={img2} alt="Carteira" />
            <strong>
              Sabia que ao investir no Plano Família você pode garantir um
              benefício fiscal?
            </strong>
            <p>
              Ao preencher a declaração do IR pelo modelo completo as suas
              contribuições para o Plano de Previdência Complementar podem ser
              deduzidas em até 12% de sua renda bruta anual.
            </p>
          </Content>

          <Content isActive={active === 2}>
            <img src={img3} alt="Carteira" />
            <strong>Flexibilidade para o seu presente e futuro!</strong>
            <p>
              Surgiu algo inesperado? <br />
              Te damos a opção de fazer resgates parciais a partir do 36° mês.
            </p>

            <div>
              <small>
                Ao continuar navegando no site, você concorda com as condições
                de{' '}
                <a
                  href="https://www.brfprevidencia.com.br/politica-de-privacidade/"
                  target="_blank"
                >
                  Privacidade
                </a>{' '}
                e{' '}
                <a
                  href="https://www.brfprevidencia.com.br/politica-de-privacidade/"
                  target="_blank"
                >
                  Termos de uso
                </a>{' '}
                do site BRF Previdência.
              </small>
              <Switch
                onChange={toggleAcceptTerms}
                checked={acceptTerms}
                checkedIcon={false}
                uncheckedIcon={false}
                height={20}
                width={40}
                handleDiameter={16}
                offColor="#FBADB4"
                offHandleColor="#fff"
                onHandleColor="#fff"
                onColor="#31D19E"
              />
            </div>
          </Content>
        </DivContent>

        <Dots quantity={quantity} active={active} />
        <Button
          color="orange"
          onClick={handleSetProximo}
          isVisible={active + 1 !== quantity}
        >
          Próximo
        </Button>
        <Button
          color="orange"
          onClick={handleStart}
          disabled={!acceptTerms}
          isVisible={active + 1 === quantity}
        >
          Começar
        </Button>

        {active > 0 && (
          <BtnVoltar type="button" onClick={handleSetAnterior}>
            &lt; Anterior
          </BtnVoltar>
        )}
      </Container>
    </>
  )
}

export default BemVindo
