/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Base64 } from 'js-base64'
import Switch from 'react-switch'
import { CircularProgress } from '@material-ui/core'
import { FiCheck, FiClock } from 'react-icons/fi'
import usePersistedState from '../../hooks/usePersistedState'
import api from '../../services/api'
import {
  ConfigData,
  UserData,
  UserAddress,
  UserAccount,
  Participant,
  ErroProps,
  UserDetails,
  FinancialIndicationData,
  LegalIndicationData,
  IndicationData,
} from '../../utils/interfaces'

import Button from '../../components/Button'
import Header from '../../components/Header'
import ModalBox from '../../components/Modal'
import {
  Container,
  Content,
  Confirmation,
  Line,
  BtnVoltar,
  BtnModal,
} from './styles'
import apiRDStation from '../../services/apiRDStation'

interface CpfFuncionario {
  cpf: string
}
const Conclusion: React.FC = () => {
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)
  const [tipoPessoa] = usePersistedState('tipoPessoa', 'F')
  const [userDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [configData] = usePersistedState<ConfigData>(
    'configData',
    {} as ConfigData,
  )
  const [userAddress] = usePersistedState<UserAddress>(
    'userAddress',
    {} as UserAddress,
  )
  const [userAccount] = usePersistedState<UserAccount>(
    'userAccount',
    {} as UserAccount,
  )
  const [participants] = usePersistedState<Participant[]>(
    'participantsGroup',
    [],
  )
  const [financialIndicationData] = usePersistedState<FinancialIndicationData>(
    'financialIndicationData',
    {} as FinancialIndicationData,
  )
  const [legalIndicationData] = usePersistedState<LegalIndicationData>(
    'legalIndicationData',
    {} as LegalIndicationData,
  )
  const [indicationData] = usePersistedState<IndicationData>(
    'indicationData',
    {} as IndicationData,
  )
  const [cpfFunc] = usePersistedState<CpfFuncionario>(
    'cpfFuncionario',
    {} as CpfFuncionario,
  )
  const [opcoes] = usePersistedState<string[]>('goalsSelected', [])
  const [selectedReceive] = usePersistedState<'PD' | 'PS' | null>(
    'receiveTypeSelected',
    null,
  )
  const [timeValueYears] = usePersistedState<number>('TimeValueYears', 5)
  const [percentualValuePercent] = usePersistedState<number>(
    'PercentualValuePercent',
    0.3,
  )
  const [, setErroProps] = usePersistedState<ErroProps>(
    'erroProps',
    {} as ErroProps,
  )

  const [aguarde, setAguarde] = useState(false)
  const [timer, setTimer] = useState(0)
  const [reSend, setReSend] = useState(true)
  const [acceptTerms, setAcceptTerms] = usePersistedState('acceptTerms', false)
  const [acceptTerms2, setAcceptTerms2] = usePersistedState(
    'acceptTerms2',
    false,
  )
  const history = useHistory()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [txtModal, setTxtModal] = useState('')
  const [flagRota, setFlagRota] = useState(false)
  const [rota, setRota] = useState('')
  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
    if (flagRota) {
      history.push(`${rota}`)
    }
  }, [flagRota, history, rota])

  const handleConfirmaAdesao = useCallback(async () => {
    setAguarde(true)

    const parametros = Base64.encode(`{
        "token": "${configData.token}",
        "versao": "${configData.tipo}",
        "plano": "${configData.plano}",
        "nome": "${userData.name.toLowerCase()}",
        "cpf": "${userData.cpf.replaceAll('.', '').replace('-', '')}",
        "nascimento": "${userData.birthdate}",
        "email": "${userData.email}",
        "inscPlano": "${
          userData.inscPlano ? userData.inscPlano.toUpperCase() : ''
        }",
        "telefone": "${userData.phone
          .replace('(', '')
          .replace(') ', '')
          .replace('-', '')}",
        "nomeMae": "${userData.parental.toLowerCase()}",
        "valor":"${userDetails.contribuicaoBasica
          .toFixed(2)
          .toString()
          .replaceAll('.', ',')}",
        "valor_resgate": "${
          selectedReceive === 'PS'
            ? percentualValuePercent.toFixed(2)
            : timeValueYears
        }",
        "forma_resgate": "${selectedReceive}",
        "tempo_contribuicao": "${userDetails.years}",
        "dataPag":"${
          userAccount.paymentType === 'B' ? userDetails.paymentDay : ''
        }",
        "formaPagamento":"${userAccount.paymentType}",
        "banco":"${userAccount.paymentType === 'D' ? userAccount.banco : ''}",
        "agencia":"${
          userAccount.paymentType === 'D' ? userAccount.agencia : ''
        }",
        "conta":"${userAccount.paymentType === 'D' ? userAccount.conta : ''}",
        "endereco": [{
          "cep":"${userAddress.cep.replace('-', '')}",
          "logradouro":"${userAddress.logradouro.toLowerCase()}",
          "bairro":"${userAddress.bairro.toLowerCase()}",
          "municipio":"${userAddress.municipio.toLowerCase()}",
          "numero":"${userAddress.numero}",
          "complemento":"${userAddress.complemento.toLowerCase()}",
          "uf":"${userAddress.uf}"
        }],
        "politicamenteexposto": "${userDetails.ppe}",
        "usperson": "${userDetails.usperson}",
        "tipoPessoa": "${tipoPessoa}",
        "cpfIndicacao": "${
          indicationData.cpfIndicacao
            ? indicationData.cpfIndicacao
                .replace('.', '')
                .replace('.', '')
                .replace('-', '')
            : ''
        }",
        "parentescoIndicacao": "${
          indicationData.grauParentescoIndicacao
            ? indicationData.grauParentescoIndicacao
            : ''
        }",
        "cpfRespFinanc": "${
          financialIndicationData.cpfResponsavelFin === undefined
            ? ''
            : financialIndicationData.cpfResponsavelFin
                .replace('.', '')
                .replace('.', '')
                .replace('-', '')
        }",
        "emailRespFinanc": "${
          financialIndicationData.emailResponsavelFin === undefined
            ? ''
            : financialIndicationData.emailResponsavelFin
        }",
        "nascRespFinanc":"${
          financialIndicationData.birthdateResponsavelFin === undefined
            ? ''
            : financialIndicationData.birthdateResponsavelFin
        }",
        "nomeRespFinanc": "${
          financialIndicationData.nomeResponsavelFin === undefined
            ? ''
            : financialIndicationData.nomeResponsavelFin.toLowerCase()
        }",
        "telRespFinanc": "${
          financialIndicationData.telResponsavelFin === undefined
            ? ''
            : `55${financialIndicationData.telResponsavelFin
                .replace('(', '')
                .replace(') ', '')
                .replace('-', '')}`
        }",
        "respLegal": [{
          "cpfRespLeg": "${
            legalIndicationData.cpfResponsavelLeg === undefined
              ? ''
              : legalIndicationData.cpfResponsavelLeg
                  .replace('.', '')
                  .replace('.', '')
                  .replace('-', '')
          }",
          "emailRespLeg": "${
            legalIndicationData.emailResponsavelLeg === undefined
              ? ''
              : legalIndicationData.emailResponsavelLeg
          }",
          "nascimentoRespLeg":"${
            legalIndicationData.birthdateResponsavelLeg === undefined
              ? ''
              : legalIndicationData.birthdateResponsavelLeg
          }",
          "nomeRespLeg": "${
            legalIndicationData.nomeResponsavelLeg === undefined
              ? ''
              : legalIndicationData.nomeResponsavelLeg.toLowerCase()
          }",
          "telRespLeg": "${
            legalIndicationData.telResponsavelLeg === undefined
              ? ''
              : `55${legalIndicationData.telResponsavelLeg
                  .replace('(', '')
                  .replace(') ', '')
                  .replace('-', '')}`
          }"}
        ],
        "objetivos": [
          ${opcoes.map(opc => `{"id": "${opc}"}`)}
        ],
        "beneficiarios": [${participants.map(
          participant => `
          {"nome": "${participant.data.name.toLowerCase()}",
          "tipo": "${participant.details.tipoBen}",
          "parentesco": "${
            participant.details.grauParentesco === '' ||
            participant.details.grauParentesco === undefined
              ? 99
              : participant.details.grauParentesco
          }",
          "nascimento": "${participant.data.birthdate}",
          "fator": "${participant.details.proporcao}",
          "cpf": "${participant.data.cpf
            .replaceAll('.', '')
            .replace('-', '')}"}`,
        )}]
        }`)

    const parametrosFinal = Base64.encode(parametros)

    await api
      .post(`wsAdesao.rule?sys=ADZ&Entrada=${parametrosFinal}`)
      .then(() => {
        localStorage.removeItem('@BRFPrevFamilia:userDetails')
        localStorage.removeItem('@BRFPrevFamilia:userAddress')
        localStorage.removeItem('@BRFPrevFamilia:userAccount')
        localStorage.removeItem('@BRFPrevFamilia:acceptTerms')
        localStorage.removeItem('@BRFPrevFamilia:acceptTerms2')
        localStorage.removeItem('@BRFPrevFamilia:erroProps')
        localStorage.removeItem('@BRFPrevFamilia:TimeValueYears')
        localStorage.removeItem('@BRFPrevFamilia:codCliente')
        localStorage.removeItem('@BRFPrevFamilia:participantsGroup')
        localStorage.removeItem('@BRFPrevFamilia:token')
        localStorage.removeItem('@BRFPrevFamilia:tipoCont')
        localStorage.removeItem('@BRFPrevFamilia:cpfFuncionario')
        localStorage.removeItem('@BRFPrevFamilia:financialIndicationData')
        localStorage.removeItem('@BRFPrevFamilia:legalIndicationData')
        localStorage.removeItem('@BRFPrevFamilia:respLegalEqualFin')
        localStorage.removeItem('@BRFPrevFamilia:tipoPessoa')
        localStorage.removeItem('@BRFPrevFamilia:respFinanceiro')
        localStorage.removeItem('@BRFPrevFamilia:acceptTerms')
        localStorage.removeItem('@BRFPrevFamilia:respLegal')
        localStorage.removeItem('@BRFPrevFamilia:menorIdade')
        localStorage.removeItem('@BRFPrevFamilia:indicationData')

        apiRDStation(
          '/conclusion',
          userData.name,
          userData.email,
          userData.phone,
        )

        history.push('/end')
      })
      .catch(res => {
        if (res.message === 'Request failed with status code 401') {
          setTxtModal('Tempo de sessão expirado')
          setIsModalOpen(true)
          setFlagRota(true)
          setRota('/')
        } else if (res.message === 'Request failed with status code 406') {
          setTxtModal(
            'Adesão não autorizada. Verifique se você já possui cadastro no plano.',
          )
          setIsModalOpen(true)
          setReSend(false)
          setTimer(20)
          setAguarde(false)
        } else if (res.message === 'Network Error') {
          setErroProps({
            title: res.message,
            description: 'Falha na conexão como servidor',
          })
          history.push('/erro')
        } else if (res.message === 'Request failed with status code 500') {
          setErroProps({
            title: 'Erro interno no servidor',
            description: res.message,
          })
          history.push('/erro')
        } else if (res.message === 'Request failed with status code 408') {
          setTxtModal('Tempo de sessão expirado')
          setIsModalOpen(true)
          setFlagRota(true)
          setRota('/register')
        } else {
          setReSend(false)
          setTimer(20)
          setAguarde(false)
          setTxtModal('Ops, algo deu errado. Tente novamente mais tarde.')
          setIsModalOpen(true)
        }
      })
  }, [
    configData,
    userData,
    userDetails,
    selectedReceive,
    percentualValuePercent,
    timeValueYears,
    userAccount,
    userAddress,
    cpfFunc.cpf,
    financialIndicationData,
    legalIndicationData,
    opcoes,
    participants,
    history,
    setErroProps,
  ])

  const toggleAcceptTerms = useCallback(() => {
    setAcceptTerms(!acceptTerms)
  }, [acceptTerms, setAcceptTerms])

  const toggleAcceptTerms2 = useCallback(() => {
    setAcceptTerms2(!acceptTerms2)
  }, [acceptTerms2, setAcceptTerms2])

  useEffect(() => {
    const countTimer = (): void => {
      if (timer > 1) {
        setTimer(timer - 1)
      } else if (timer === 1) {
        setTimer(timer - 1)
        setReSend(true)
      }
    }
    const interval = setInterval(countTimer, 1000)
    return () => clearInterval(interval)
  }, [timer])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <Confirmation>
            <strong>Da filiação à Abrapp:</strong>
            <div>
              <p>
                Eu <b>{userData.name}</b>, inscrito no CPF <b>{userData.cpf}</b>
                , declaro estar ciente da minha filiação na qualidade de
                Associado Especial Previdenciário da ABRAPP, por ser requisito
                necessário para a minha participação no Plano Família da BRF
                Previdência, sem qualquer custo ou obrigação adicional.
                <Switch
                  onChange={toggleAcceptTerms}
                  checked={acceptTerms}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  height={20}
                  width={40}
                  handleDiameter={16}
                  offColor="#FBADB4"
                  offHandleColor="#fff"
                  onHandleColor="#fff"
                  onColor="#31D19E"
                />
              </p>
            </div>
            <Line />
            <strong>Da responsabilidade do participante:</strong>
            <div>
              <p>
                Eu <b>{userData.name}</b>, inscrito no CPF <b>{userData.cpf}</b>
                , declaro estar de inteira responsabilidade pelas informações
                realizadas, estando ciente de que a falsidade nas informações
                prestadas configura crime previsto no Código Penal Brasileiro,
                passível de apuração na forma da Lei, bem como poderá ser
                enquadrada como Litigância de Má Fé.
                <Switch
                  onChange={toggleAcceptTerms2}
                  checked={acceptTerms2}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  height={20}
                  width={40}
                  handleDiameter={16}
                  offColor="#FBADB4"
                  offHandleColor="#fff"
                  onHandleColor="#fff"
                  onColor="#31D19E"
                />
              </p>
            </div>
          </Confirmation>
        </Content>

        <Button
          type="button"
          color="orange"
          width="large"
          disabled={!acceptTerms || !acceptTerms2 || !reSend || aguarde}
          onClick={handleConfirmaAdesao}
        >
          {!aguarde ? (
            !reSend ? (
              <>
                <FiClock size={45} />
                <span>
                  Tente novamente em {timer < 10 ? `0${timer}` : timer} segundos
                </span>
              </>
            ) : (
              <>
                <FiCheck size={45} />
                <span>Confirmar adesão</span>
              </>
            )
          ) : (
            <>
              <CircularProgress color="inherit" />
              <span>Aguarde</span>
            </>
          )}
        </Button>

        <BtnVoltar type="button" onClick={() => history.goBack()}>
          &lt; Anterior
        </BtnVoltar>
        <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          {txtModal}
          <BtnModal isActive onClick={() => handleCloseModal()}>
            Ok
          </BtnModal>
        </ModalBox>
      </Container>
    </>
  )
}

export default Conclusion
