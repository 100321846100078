import React from 'react'

import Logo from '../Logo'

import { Container } from './styles'

const Header: React.FC = ({ children }) => {
  return (
    <Container>
      <div>
        <div>{children}</div>
        <Logo />
      </div>
    </Container>
  )
}

export default Header
