import { useState, useEffect, Dispatch, SetStateAction } from 'react'

type Response<T> = [
  T,
  Dispatch<SetStateAction<T>>
]

function usePersistedState<T>(key: string, initialState: T): Response<T> {
  const [state, setState] = useState(() => {
    const storedValue = localStorage.getItem(`@BRFPrevFamilia:${key}`)

    if (storedValue) {
      return JSON.parse(storedValue)
    }
    return initialState
  })

  useEffect(() => {
    localStorage.setItem(`@BRFPrevFamilia:${key}`, JSON.stringify(state))
  }, [key, state])

  return [state, setState]
}

export default usePersistedState
