/* eslint-disable radix */
import React, { useRef, useCallback, useState, useEffect } from 'react'
import { FiClock } from 'react-icons/fi'
import * as Yup from 'yup'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import { useHistory } from 'react-router-dom'
import { Container, Content, BoxValue, BtnVoltar, Line } from './styles'
import Header from '../../components/Header'
import usePersistedState from '../../hooks/usePersistedState'
import Input from '../../components/Input'
import wallet from '../../assets/wallet.svg'
import Button from '../../components/Button'
import InputHidden from '../../components/InputHidden'
import getValidationErrors from '../../utils/getValidationErrors'
import { ValorMascarar } from '../../utils/masks'

import { UserData, UserDetails } from '../../utils/interfaces'

const Contribution: React.FC = () => {
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)
  const [userDetails, setUserDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )

  const [contribValue, setContribValue] = useState(
    userDetails.contribuicaoBasica === null ||
      userDetails.contribuicaoBasica === undefined
      ? 10
      : userDetails.contribuicaoBasica,
  )

  const [dcrContribValue, setDcrContribValue] = useState(
    contribValue && contribValue !== 10
      ? ValorMascarar(`${contribValue.toFixed(2)}`)
      : '10,00',
  )

  const history = useHistory()
  const formRef = useRef<FormHandles>(null)

  const handleClick = useCallback(() => {
    formRef.current?.submitForm()
  }, [])

  const changeFormContrib = useCallback(
    val => {
      const field = formRef.current?.getFieldRef('contribution')
      field.value = val
      setUserDetails(() => ({ ...userDetails, contribuicaoBasica: val }))
    },
    [setUserDetails, userDetails],
  )

  const mudarContrib = useCallback(
    valor => {
      const v = valor.replaceAll('.', '').replace(',', '.').replace('R$ ', '')
      setContribValue(parseFloat(v))
      setDcrContribValue(ValorMascarar(valor))
    },
    [setContribValue],
  )

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          years: Yup.number()
            .typeError('Digite o tempo para realizar a simulação')
            .min(1)
            .max(50, 'O tempo máximo para contribuição é de 50 anos')
            .required('Digite o tempo para realizar a simulação'),
        })

        await schema.validate(data, { abortEarly: false })

        setUserDetails({
          ...userDetails,
          contribuicaoBasica: contribValue * 1,
          years: data.years,
        })

        history.push('/simulation')
      } catch (err) {
        formRef.current?.setErrors(getValidationErrors(err))
      }
    },
    [contribValue, history, setUserDetails, userDetails],
  )

  const validarContrib = useCallback(
    valor => {
      const v = valor.replaceAll('.', '').replace(',', '.').replace('R$ ', '')
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(v)) {
        setContribValue(0)
        setDcrContribValue('0,00')
      } else {
        setContribValue(parseFloat(v))
        setDcrContribValue(ValorMascarar(valor))
      }
    },
    [setContribValue],
  )

  useEffect(() => {
    changeFormContrib(contribValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contribValue])

  return (
    <>
      <Header />
      <Container>
        <Content>
          {/* <strong>
            Queremos saber mais sobre você, {userData.name.split(' ')[0]}:
          </strong>
          <strong>Conta pra gente:</strong> */}
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
              email: userData.email,
              phone: userData.phone,
              birthdate: userData.birthdate,
              parental: userData.parental,
              years: userDetails.years,
              contribution: userDetails.contribuicaoBasica,
            }}
          >
            <InputHidden name="contribution" type="hidden" />
            <Input
              icon={FiClock}
              sizeBox="large"
              name="years"
              type="number"
              min={1}
              sufix="anos"
              placeholder="Por quantos anos deseja contribuir?"
            />
          </Form>

          <Line />

          <BoxValue>
            <p>Qual valor mensal?</p>
            <div>
              <img src={wallet} alt="wallet" />
              <div>
                <p>Quero investir:</p>
                <input
                  id="contribution"
                  value={`R$ ${ValorMascarar(dcrContribValue)}`}
                  onChange={e => mudarContrib(e.target.value)}
                  min="0"
                  onBlur={e => validarContrib(e.target.value)}
                />
                <small>Valor mensal recorrente</small>
              </div>
            </div>
            <small>
              Está em dúvida? Não se preocupe, depois você poderá alterar este
              valor sempre que desejar.
            </small>
          </BoxValue>
        </Content>

        <Button type="submit" color="orange" onClick={handleClick}>
          Simular Agora
        </Button>
        <BtnVoltar type="button" onClick={() => history.goBack()}>
          &lt; Anterior
        </BtnVoltar>
      </Container>
    </>
  )
}

export default Contribution
