import React, { ButtonHTMLAttributes } from 'react'

import { Container } from './styles'

interface PropsBtn<T> extends ButtonHTMLAttributes<T> {
  color?:
    | 'green'
    | 'pink'
    | 'white'
    | 'orange'
    | 'blue'
    | 'darkGreen'
    | 'yellow'
  fontSize?: 'small' | 'normal' | 'large'
  width?: 'small' | 'medium' | 'large'
  isVisible?: boolean
}

type Props = PropsBtn<HTMLButtonElement>

const Button: React.FC<Props> = ({
  children,
  isVisible = true,
  fontSize,
  width,
  color = 'white',
  ...rest
}) => {
  return isVisible ? (
    <Container
      type="button"
      color={color}
      {...rest}
      fontSize={fontSize}
      width={width}
    >
      {children}
    </Container>
  ) : null
}

export default Button
